const initialState = {
  detailGetMarketing: true,
  detailKunjunganMarketing: true,
  dataKunjungan: [],
  dataGetMarketing: [],
  getKunjunganAllMarketing: [],
  detailGetKunjunganAllMarketing: true,
  urlDokumenKunjungan: "",
};

const kunjunganReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_DETAIL_MARKETING":
      return {
        ...state,
        detailGetMarketing: data,
      };
    case "SET_DETAIL_KUNJUNGAN_MARKETING":
      return {
        ...state,
        detailKunjunganMarketing: data,
      };
    case "SET_ALL_KUNJUNGAN":
      return {
        ...state,
        dataKunjungan: data,
      };
    case "SET_GET_MARKETING":
      return {
        ...state,
        dataGetMarketing: data,
      };
    case "SET_GET_KUNJUNGAN_ALL_SM":
      return {
        ...state,
        getKunjunganAllMarketing: data,
      };
    case "SET_DETAIL_KUNJUNGAN":
      return {
        ...state,
        detailGetKunjunganAllMarketing: data,
      };
    case "SHOW_URL_DOKUMEN_KUNJUNGAN":
      return {
        ...state,
        urlDokumenKunjungan: data,
      };
    default:
      return state;
  }
};
export default kunjunganReducer;
