import React from "react";
import {
  Button,
  Card,
  Form,
  Col,
  Modal,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Label } from "reactstrap";
import { Document, Page, pdfjs } from "react-pdf";
import { showUrlDokumen } from "stores/Pengajuan/function";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Step6() {
  const dispatch = useDispatch();
  const pengajuan = useSelector((state) => state.pengajuanReducer);
  const valDetail = !pengajuan.detailPengajuan.stepEnam
    ? []
    : pengajuan.detailPengajuan.stepEnam;
  // console.log(valDetail, "LLLLLLL");
  const allDetail = pengajuan.detailPengajuan;
  const [listUrlDokumen, setListUrlDokumen] = React.useState([]);

  const handleClickPdf = (url) => {
    dispatch({
      type: "PREVIEW_DOKUMEN",
      data: showUrlDokumen(dispatch, { urlImage: url }),
    });
  };

  React.useEffect(() => {
    let tmp = [];
    // let newTmpStatusPekerjaan = [];
    // let newTmpStatusMenikah = [];
    Object.entries(valDetail).forEach(([key, value]) => {
      if (
        key != "_id" &&
        key != "id_pinjaman" &&
        key != "createdAt" &&
        key != "updatedAt"
      ) {
        tmp.push({
          key: key,
          url: value,
        });
      }
    });

    // tmp.map((val1) => {
    //   if (allDetail.stepTiga.statusPekerjaan != "Pensiun") {
    //     if (val1.key != "karip") {
    //       newTmpStatusPekerjaan.push(val1);
    //     }
    //   } else if (allDetail.stepTiga.statusPekerjaan === "Pensiun") {
    //     newTmpStatusPekerjaan.push(val1);
    //   }
    // });

    // newTmpStatusPekerjaan.map((val1) => {
    //   if (
    //     allDetail.statusMenikah === "Cerai Hidup" ||
    //     allDetail.statusMenikah === "Cerai Mati"
    //   ) {
    //     if (
    //       val1.key != "buku_nikah" ||
    //       val1.key != "pas_foto_pasangan" ||
    //       val1.key != "ktp_pasangan"
    //     ) {
    //       console.log(val1, "PEPEPEPEPEPEPEPPEPEPEP");
    //       newTmpStatusMenikah.push(val1);
    //     }
    //   } else if (
    //     allDetail.statusMenikah != "Cerai Hidup" ||
    //     allDetail.statusMenikah != "Cerai Mati"
    //   ) {
    //     if (val1.key != "akta_cerai") {
    //       newTmpStatusMenikah.push(val1);
    //     }
    //   }
    // });
    // console.log(tmp, "??????????????????????????????????????????????");
    setListUrlDokumen(tmp);
  }, []);

  return (
    <Col md="12" style={{ marginTop: 20 }}>
      <Card.Header>
        <Card.Title
          as="h4"
          style={{
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          Dokumen
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card style={{ border: 0 }}>
          <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
            <div className="row">
              <div className="col-12">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  PDF
                </Label>
                <Row>
                  <Col md={"6"}>
                    <Label
                      style={{
                        textTransform: "uppercase",
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      Pas Foto Diri
                    </Label>
                  </Col>
                  <Col md={"3"}>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Preview Dokumen</Tooltip>}
                    >
                      <Button
                        className=" mr-0"
                        // variant="primary"
                        onClick={() => {
                          handleClickPdf(valDetail.pas_foto_diri);
                        }}
                        style={{
                          fontSize: 20,
                          marginBottom: 15,
                          textTransform: "capitalize",
                          marginLeft: 10,
                          backgroundColor: "#00BFFF",
                          border: 0,
                          paddingRight: 40,
                          paddingLeft: 40,
                        }}
                      >
                        <i class="fas fa-file-pdf"></i>
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
                <p
                  style={{
                    fontSize: 15,
                    color: "blue",
                  }}
                >
                  {valDetail.pas_foto_diri}
                </p>
                <Row>
                  <Col md={"6"}>
                    <Label
                      style={{
                        textTransform: "uppercase",
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      KTP Diri
                    </Label>
                  </Col>
                  <Col md={"3"}>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Preview Dokumen</Tooltip>}
                    >
                      <Button
                        className=" mr-0"
                        // variant="primary"
                        onClick={() => {
                          handleClickPdf(valDetail.ktp_diri);
                        }}
                        style={{
                          fontSize: 20,
                          marginBottom: 15,
                          textTransform: "capitalize",
                          marginLeft: 10,
                          backgroundColor: "#00BFFF",
                          border: 0,
                          paddingRight: 40,
                          paddingLeft: 40,
                        }}
                      >
                        <i class="fas fa-file-pdf"></i>
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
                <p
                  style={{
                    fontSize: 15,
                    color: "blue",
                  }}
                >
                  {valDetail.ktp_diri}
                </p>
                <Row>
                  <Col md={"6"}>
                    <Label
                      style={{
                        textTransform: "uppercase",
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      Kartu Keluarga
                    </Label>
                  </Col>
                  <Col md={"3"}>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Preview Dokumen</Tooltip>}
                    >
                      <Button
                        className=" mr-0"
                        // variant="primary"
                        onClick={() => {
                          handleClickPdf(valDetail.kartu_keluarga);
                        }}
                        style={{
                          fontSize: 20,
                          marginBottom: 15,
                          textTransform: "capitalize",
                          marginLeft: 10,
                          backgroundColor: "#00BFFF",
                          border: 0,
                          paddingRight: 40,
                          paddingLeft: 40,
                        }}
                      >
                        <i class="fas fa-file-pdf"></i>
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
                <p
                  style={{
                    fontSize: 15,
                    color: "blue",
                  }}
                >
                  {valDetail.kartu_keluarga}
                </p>
                <Row>
                  <Col md={"6"}>
                    <Label
                      style={{
                        textTransform: "uppercase",
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      NPWP
                    </Label>
                  </Col>
                  <Col md={"3"}>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Preview Dokumen</Tooltip>}
                    >
                      <Button
                        className=" mr-0"
                        // variant="primary"
                        onClick={() => {
                          handleClickPdf(valDetail.npwp);
                        }}
                        style={{
                          fontSize: 20,
                          marginBottom: 15,
                          textTransform: "capitalize",
                          marginLeft: 10,
                          backgroundColor: "#00BFFF",
                          border: 0,
                          paddingRight: 40,
                          paddingLeft: 40,
                        }}
                      >
                        <i class="fas fa-file-pdf"></i>
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
                <p
                  style={{
                    fontSize: 15,
                    color: "blue",
                  }}
                >
                  {valDetail.npwp}
                </p>
                {allDetail.statusMenikah === "Cerai Hidup" ||
                allDetail.statusMenikah === "Cerai Mati" ? (
                  <Row>
                    <Col md={"6"}>
                      <Label
                        style={{
                          textTransform: "uppercase",
                          fontSize: 15,
                          fontWeight: "bold",
                          color: "#000000",
                        }}
                      >
                        Akta Cerai
                      </Label>
                    </Col>
                    <Col md={"3"}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Preview Dokumen</Tooltip>}
                      >
                        <Button
                          className=" mr-0"
                          // variant="primary"
                          onClick={() => {
                            handleClickPdf(valDetail.akta_cerai);
                          }}
                          style={{
                            fontSize: 20,
                            marginBottom: 15,
                            textTransform: "capitalize",
                            marginLeft: 10,
                            backgroundColor: "#00BFFF",
                            border: 0,
                            paddingRight: 40,
                            paddingLeft: 40,
                          }}
                        >
                          <i class="fas fa-file-pdf"></i>
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                ) : null}
                {allDetail.statusMenikah === "Cerai Hidup" ||
                allDetail.statusMenikah === "Cerai Mati" ? (
                  <p
                    style={{
                      fontSize: 15,
                      color: "blue",
                    }}
                  >
                    {valDetail.akta_cerai}
                  </p>
                ) : null}
                {allDetail.statusMenikah === "Kawin" ? (
                  <Row>
                    <Col md={"6"}>
                      <Label
                        style={{
                          textTransform: "uppercase",
                          fontSize: 15,
                          fontWeight: "bold",
                          color: "#000000",
                        }}
                      >
                        Pas Foto Pasangan
                      </Label>
                    </Col>
                    <Col md={"3"}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Preview Dokumen</Tooltip>}
                      >
                        <Button
                          className=" mr-0"
                          // variant="primary"
                          onClick={() => {
                            handleClickPdf(valDetail.pas_foto_pasangan);
                          }}
                          style={{
                            fontSize: 20,
                            marginBottom: 15,
                            textTransform: "capitalize",
                            marginLeft: 10,
                            backgroundColor: "#00BFFF",
                            border: 0,
                            paddingRight: 40,
                            paddingLeft: 40,
                          }}
                        >
                          <i class="fas fa-file-pdf"></i>
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                ) : null}
                {allDetail.statusMenikah === "Kawin" ? (
                  <p
                    style={{
                      fontSize: 15,
                      color: "blue",
                    }}
                  >
                    {valDetail.pas_foto_pasangan}
                  </p>
                ) : null}
                {allDetail.statusMenikah === "Kawin" ? (
                  <Row>
                    <Col md={"6"}>
                      <Label
                        style={{
                          textTransform: "uppercase",
                          fontSize: 15,
                          fontWeight: "bold",
                          color: "#000000",
                        }}
                      >
                        KTP Pasangan
                      </Label>
                    </Col>
                    <Col md={"3"}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Preview Dokumen</Tooltip>}
                      >
                        <Button
                          className=" mr-0"
                          // variant="primary"
                          onClick={() => {
                            handleClickPdf(valDetail.ktp_pasangan);
                          }}
                          style={{
                            fontSize: 20,
                            marginBottom: 15,
                            textTransform: "capitalize",
                            marginLeft: 10,
                            backgroundColor: "#00BFFF",
                            border: 0,
                            paddingRight: 40,
                            paddingLeft: 40,
                          }}
                        >
                          <i class="fas fa-file-pdf"></i>
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                ) : null}
                {allDetail.statusMenikah === "Kawin" ? (
                  <p
                    style={{
                      fontSize: 15,
                      color: "blue",
                    }}
                  >
                    {valDetail.ktp_pasangan}
                  </p>
                ) : null}
                {allDetail.statusMenikah === "Kawin" ? (
                  <Row>
                    <Col md={"6"}>
                      <Label
                        style={{
                          textTransform: "uppercase",
                          fontSize: 15,
                          fontWeight: "bold",
                          color: "#000000",
                        }}
                      >
                        Buku Nikah
                      </Label>
                    </Col>
                    <Col md={"3"}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Preview Dokumen</Tooltip>}
                      >
                        <Button
                          className=" mr-0"
                          // variant="primary"
                          onClick={() => {
                            handleClickPdf(valDetail.buku_nikah);
                          }}
                          style={{
                            fontSize: 20,
                            marginBottom: 15,
                            textTransform: "capitalize",
                            marginLeft: 10,
                            backgroundColor: "#00BFFF",
                            border: 0,
                            paddingRight: 40,
                            paddingLeft: 40,
                          }}
                        >
                          <i class="fas fa-file-pdf"></i>
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                ) : null}
                {allDetail.statusMenikah === "Kawin" ? (
                  <p
                    style={{
                      fontSize: 15,
                      color: "blue",
                    }}
                  >
                    {valDetail.buku_nikah}
                  </p>
                ) : null}
                <Row>
                  <Col md={"6"}>
                    <Label
                      style={{
                        textTransform: "uppercase",
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      Slip Gaji
                    </Label>
                  </Col>
                  <Col md={"3"}>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Preview Dokumen</Tooltip>}
                    >
                      <Button
                        className=" mr-0"
                        // variant="primary"
                        onClick={() => {
                          handleClickPdf(valDetail.slip_gaji);
                        }}
                        style={{
                          fontSize: 20,
                          marginBottom: 15,
                          textTransform: "capitalize",
                          marginLeft: 10,
                          backgroundColor: "#00BFFF",
                          border: 0,
                          paddingRight: 40,
                          paddingLeft: 40,
                        }}
                      >
                        <i class="fas fa-file-pdf"></i>
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
                <p
                  style={{
                    fontSize: 15,
                    color: "blue",
                  }}
                >
                  {valDetail.slip_gaji}
                </p>
                <Row>
                  <Col md={"6"}>
                    <Label
                      style={{
                        textTransform: "uppercase",
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      Daftar Gaji
                    </Label>
                  </Col>
                  <Col md={"3"}>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Preview Dokumen</Tooltip>}
                    >
                      <Button
                        className=" mr-0"
                        // variant="primary"
                        onClick={() => {
                          handleClickPdf(valDetail.daftar_gaji);
                        }}
                        style={{
                          fontSize: 20,
                          marginBottom: 15,
                          textTransform: "capitalize",
                          marginLeft: 10,
                          backgroundColor: "#00BFFF",
                          border: 0,
                          paddingRight: 40,
                          paddingLeft: 40,
                        }}
                      >
                        <i class="fas fa-file-pdf"></i>
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
                <p
                  style={{
                    fontSize: 15,
                    color: "blue",
                  }}
                >
                  {valDetail.daftar_gaji}
                </p>
                <Row>
                  <Col md={"6"}>
                    <Label
                      style={{
                        textTransform: "uppercase",
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      Buku Tabungan
                    </Label>
                  </Col>
                  <Col md={"3"}>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Preview Dokumen</Tooltip>}
                    >
                      <Button
                        className=" mr-0"
                        // variant="primary"
                        onClick={() => {
                          handleClickPdf(valDetail.buku_tabungan);
                        }}
                        style={{
                          fontSize: 20,
                          marginBottom: 15,
                          textTransform: "capitalize",
                          marginLeft: 10,
                          backgroundColor: "#00BFFF",
                          border: 0,
                          paddingRight: 40,
                          paddingLeft: 40,
                        }}
                      >
                        <i class="fas fa-file-pdf"></i>
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
                <p
                  style={{
                    fontSize: 15,
                    color: "blue",
                  }}
                >
                  {valDetail.buku_tabungan}
                </p>
                <Row>
                  <Col md={"6"}>
                    <Label
                      style={{
                        textTransform: "uppercase",
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      Kartu Pegawai
                    </Label>
                  </Col>
                  <Col md={"3"}>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Preview Dokumen</Tooltip>}
                    >
                      <Button
                        className=" mr-0"
                        // variant="primary"
                        onClick={() => {
                          handleClickPdf(valDetail.kartu_pegawai);
                        }}
                        style={{
                          fontSize: 20,
                          marginBottom: 15,
                          textTransform: "capitalize",
                          marginLeft: 10,
                          backgroundColor: "#00BFFF",
                          border: 0,
                          paddingRight: 40,
                          paddingLeft: 40,
                        }}
                      >
                        <i class="fas fa-file-pdf"></i>
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
                <p
                  style={{
                    fontSize: 15,
                    color: "blue",
                  }}
                >
                  {valDetail.kartu_pegawai}
                </p>
                <Row>
                  <Col md={"6"}>
                    <Label
                      style={{
                        textTransform: "uppercase",
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      Kartu Taspen
                    </Label>
                  </Col>
                  <Col md={"3"}>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Preview Dokumen</Tooltip>}
                    >
                      <Button
                        className=" mr-0"
                        // variant="primary"
                        onClick={() => {
                          handleClickPdf(valDetail.kartu_taspen);
                        }}
                        style={{
                          fontSize: 20,
                          marginBottom: 15,
                          textTransform: "capitalize",
                          marginLeft: 10,
                          backgroundColor: "#00BFFF",
                          border: 0,
                          paddingRight: 40,
                          paddingLeft: 40,
                        }}
                      >
                        <i class="fas fa-file-pdf"></i>
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
                <p
                  style={{
                    fontSize: 15,
                    color: "blue",
                  }}
                >
                  {valDetail.kartu_taspen}
                </p>
                {allDetail.stepTiga.statusPekerjaan === "Pensiun" ? (
                  <Row>
                    <Col md={"6"}>
                      <Label
                        style={{
                          textTransform: "uppercase",
                          fontSize: 15,
                          fontWeight: "bold",
                          color: "#000000",
                        }}
                      >
                        Karip
                      </Label>
                    </Col>
                    <Col md={"3"}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Preview Dokumen</Tooltip>}
                      >
                        <Button
                          className=" mr-0"
                          // variant="primary"
                          onClick={() => {
                            handleClickPdf(valDetail.karip);
                          }}
                          style={{
                            fontSize: 20,
                            marginBottom: 15,
                            textTransform: "capitalize",
                            marginLeft: 10,
                            backgroundColor: "#00BFFF",
                            border: 0,
                            paddingRight: 40,
                            paddingLeft: 40,
                          }}
                        >
                          <i class="fas fa-file-pdf"></i>
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                ) : null}
                {allDetail.stepTiga.statusPekerjaan === "Pensiun" ? (
                  <p
                    style={{
                      fontSize: 15,
                      color: "blue",
                    }}
                  >
                    {valDetail.karip}
                  </p>
                ) : null}
                <Row>
                  <Col md={"6"}>
                    <Label
                      style={{
                        textTransform: "uppercase",
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      SK / Jaminan
                    </Label>
                  </Col>
                  <Col md={"3"}>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Preview Dokumen</Tooltip>}
                    >
                      <Button
                        className=" mr-0"
                        // variant="primary"
                        onClick={() => {
                          handleClickPdf(valDetail.sk);
                        }}
                        style={{
                          fontSize: 20,
                          marginBottom: 15,
                          textTransform: "capitalize",
                          marginLeft: 10,
                          backgroundColor: "#00BFFF",
                          border: 0,
                          paddingRight: 40,
                          paddingLeft: 40,
                        }}
                      >
                        <i class="fas fa-file-pdf"></i>
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
                <p
                  style={{
                    fontSize: 15,
                    color: "blue",
                  }}
                >
                  {valDetail.sk}
                </p>

                <Row>
                  <Col md={"6"}>
                    <Label
                      style={{
                        textTransform: "uppercase",
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      Surat Keterangan Sehat
                    </Label>
                  </Col>
                  <Col md={"3"}>
                    {valDetail.surat_keterangan_sehat ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Preview Dokumen</Tooltip>}
                      >
                        <Button
                          className=" mr-0"
                          // variant="primary"
                          onClick={() => {
                            handleClickPdf(valDetail.surat_keterangan_sehat);
                          }}
                          style={{
                            fontSize: 20,
                            marginBottom: 15,
                            textTransform: "capitalize",
                            marginLeft: 10,
                            backgroundColor: "#00BFFF",
                            border: 0,
                            paddingRight: 40,
                            paddingLeft: 40,
                          }}
                        >
                          <i class="fas fa-file-pdf"></i>
                        </Button>
                      </OverlayTrigger>
                    ) : (
                      <Button
                        className=" mr-0"
                        // variant="primary"
                        // onClick={() => {
                        //   handleClickPdf(valDetail.surat_keterangan_sehat);
                        // }}
                        style={{
                          fontSize: 20,
                          marginBottom: 15,
                          textTransform: "capitalize",
                          marginLeft: 10,
                          backgroundColor: "#00BFFF",
                          border: 0,
                          paddingRight: 40,
                          paddingLeft: 40,
                        }}
                      >
                        -
                      </Button>
                    )}
                  </Col>
                </Row>
                {valDetail.surat_keterangan_sehat ? (
                  <p style={{ fontSize: 15, color: "blue" }}>
                    {valDetail.surat_keterangan_sehat}
                  </p>
                ) : (
                  <p style={{ fontSize: 15, color: "blue" }}>-</p>
                )}

                {allDetail.tipeNasabah === "002" ? (
                  <Row>
                    <Col md={"6"}>
                      <Label
                        style={{
                          textTransform: "uppercase",
                          fontSize: 15,
                          fontWeight: "bold",
                          color: "#000000",
                        }}
                      >
                        Ijin Usaha
                      </Label>
                    </Col>
                    <Col md={"3"}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Preview Dokumen</Tooltip>}
                      >
                        <Button
                          className=" mr-0"
                          // variant="primary"
                          onClick={() => {
                            handleClickPdf(valDetail.izin_usaha);
                          }}
                          style={{
                            fontSize: 20,
                            marginBottom: 15,
                            textTransform: "capitalize",
                            marginLeft: 10,
                            backgroundColor: "#00BFFF",
                            border: 0,
                            paddingRight: 40,
                            paddingLeft: 40,
                          }}
                        >
                          <i class="fas fa-file-pdf"></i>
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                ) : null}
                {allDetail.tipeNasabah === "002" ? (
                  <p
                    style={{
                      fontSize: 15,
                      color: "blue",
                    }}
                  >
                    {valDetail.izin_usaha}
                  </p>
                ) : null}
                {allDetail.tipeNasabah === "002" ? (
                  <Row>
                    <Col md={"6"}>
                      <Label
                        style={{
                          textTransform: "uppercase",
                          fontSize: 15,
                          fontWeight: "bold",
                          color: "#000000",
                        }}
                      >
                        Rekening Koran Usaha
                      </Label>
                    </Col>
                    <Col md={"3"}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Preview Dokumen</Tooltip>}
                      >
                        <Button
                          className=" mr-0"
                          // variant="primary"
                          onClick={() => {
                            handleClickPdf(valDetail.rekening_koran_usaha);
                          }}
                          style={{
                            fontSize: 20,
                            marginBottom: 15,
                            textTransform: "capitalize",
                            marginLeft: 10,
                            backgroundColor: "#00BFFF",
                            border: 0,
                            paddingRight: 40,
                            paddingLeft: 40,
                          }}
                        >
                          <i class="fas fa-file-pdf"></i>
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                ) : null}
                {allDetail.tipeNasabah === "002" ? (
                  <p
                    style={{
                      fontSize: 15,
                      color: "blue",
                    }}
                  >
                    {valDetail.rekening_koran_usaha}
                  </p>
                ) : null}
                {allDetail.tipeNasabah === "002" ? (
                  <Row>
                    <Col md={"6"}>
                      <Label
                        style={{
                          textTransform: "uppercase",
                          fontSize: 15,
                          fontWeight: "bold",
                          color: "#000000",
                        }}
                      >
                        Nota Usaha
                      </Label>
                    </Col>
                    <Col md={"3"}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Preview Dokumen</Tooltip>}
                      >
                        <Button
                          className=" mr-0"
                          // variant="primary"
                          onClick={() => {
                            handleClickPdf(valDetail.nota_usaha);
                          }}
                          style={{
                            fontSize: 20,
                            marginBottom: 15,
                            textTransform: "capitalize",
                            marginLeft: 10,
                            backgroundColor: "#00BFFF",
                            border: 0,
                            paddingRight: 40,
                            paddingLeft: 40,
                          }}
                        >
                          <i class="fas fa-file-pdf"></i>
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                ) : null}
                {allDetail.tipeNasabah === "002" ? (
                  <p
                    style={{
                      fontSize: 15,
                      color: "blue",
                    }}
                  >
                    {valDetail.nota_usaha}
                  </p>
                ) : null}
                {allDetail.tipeNasabah === "002" ? (
                  <Row>
                    <Col md={"6"}>
                      <Label
                        style={{
                          textTransform: "uppercase",
                          fontSize: 15,
                          fontWeight: "bold",
                          color: "#000000",
                        }}
                      >
                        Denah Usaha
                      </Label>
                    </Col>
                    <Col md={"3"}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Preview Dokumen</Tooltip>}
                      >
                        <Button
                          className=" mr-0"
                          // variant="primary"
                          onClick={() => {
                            handleClickPdf(valDetail.denah_usaha);
                          }}
                          style={{
                            fontSize: 20,
                            marginBottom: 15,
                            textTransform: "capitalize",
                            marginLeft: 10,
                            backgroundColor: "#00BFFF",
                            border: 0,
                            paddingRight: 40,
                            paddingLeft: 40,
                          }}
                        >
                          <i class="fas fa-file-pdf"></i>
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                ) : null}
                {allDetail.tipeNasabah === "002" ? (
                  <p
                    style={{
                      fontSize: 15,
                      color: "blue",
                    }}
                  >
                    {valDetail.denah_usaha}
                  </p>
                ) : null}
                {/* {listUrlDokumen.map((val) => {
                  return (
                    <>
                      <Row>
                        <Col md={"6"}>
                          <Label
                            style={{
                              textTransform: "uppercase",
                              fontSize: 15,
                              fontWeight: "bold",
                              color: "#000000",
                            }}
                          >
                            {val.key}
                          </Label>
                        </Col>
                        <Col md={"3"}>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Preview Dokumen</Tooltip>}
                          >
                            <Button
                              className=" mr-0"
                              // variant="primary"
                              onClick={() => {
                                handleClickPdf(val.url);
                              }}
                              style={{
                                fontSize: 20,
                                marginBottom: 15,
                                textTransform: "capitalize",
                                marginLeft: 10,
                                backgroundColor: "#00BFFF",
                                border: 0,
                                paddingRight: 40,
                                paddingLeft: 40,
                              }}
                            >
                              <i class="fas fa-file-pdf"></i>
                            </Button>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <p
                        style={{
                          fontSize: 15,
                          color: "blue",
                        }}
                      >
                        {val.url}
                      </p>
                    </>
                  );
                })} */}
              </div>
            </div>
          </div>
        </Card>
      </Card.Body>
    </Col>
  );
}
export default Step6;
