const initialState = {
  modalDetailPengajuan: true,
  listPengajuan: [],
  listPencairan: [],
  listStatus: [],
  detailPengajuan: [],
  totalPencairan: [],
  valueCol: "12",
  valueColPrev: "0",
  urlPreview: "",
  titlePreview: "",
  urlDokumen: "",
  urlDokumenDisetujui: [],
};

const pengajuanReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_STEP_PENGAJUAN":
      return {
        ...state,
        modalDetailPengajuan: data,
      };
    case "SET_ALL_STATUS":
      return {
        ...state,
        listStatus: data,
      };
    case "SET_ALL_PENGAJUAN":
      return {
        ...state,
        listPengajuan: data,
      };
    case "SET_ALL_PENCAIRAN":
      return {
        ...state,
        listPencairan: data,
      };
    case "SET_ALL_TOTAL_PENCAIRAN":
      return {
        ...state,
        totalPencairan: data,
      };
    case "SET_DETAIL_PENGAJUAN":
      return {
        ...state,
        detailPengajuan: data,
      };
    case "PREVIEW_DOKUMEN":
      return {
        ...state,
        valueCol: "6",
        valueColPrev: "6",
        urlPreview: data,
        titlePreview: data,
      };
    case "HIDE_DOKUMEN":
      return {
        ...state,
        valueCol: "12",
        valueColPrev: "0",
        urlPreview: "",
        titlePreview: "",
      };
    case "SHOW_URL_DOKUMEN":
      return {
        ...state,
        urlDokumen: data,
      };
    case "SHOW_URL_DOKUMEN_DISETUJUI":
      return {
        ...state,
        urlDokumenDisetujui: data,
      };
    default:
      return state;
  }
};
export default pengajuanReducer;
