import { baseAxios } from "../index";

export async function getAllKunjunganByLogin(dispatch, data, history) {
  // console.log(data, "AAA");
  try {
    const response = await baseAxios.get(
      `webKorcab/getAllKunjunganByLogin?page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_KUNJUNGAN", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getMarketing(dispatch, data, history) {
  // console.log(data, "AAA");
  try {
    const response = await baseAxios.get(
      `webKorcab/getMarketing?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}&idCabang=${data.idCabang}&idMarketing=${data.idMarketing}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_GET_MARKETING", data: response.data });
    // console.log(response, "getMarketing");
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function exportPdfKunjungan(data) {
  try {
    const response = await baseAxios.get(
      `webKorcab/exportKunjungan?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}&idMarketing=${data.idMarketing}`,
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // console.log(response.data, "RESPONSE");
    return response.data;
  } catch (err) {
    console.error("Error fetching PDF:", err);
    throw err;
  }
}

export async function exportPdfKunjunganMobile(data) {
  try {
    const response = await baseAxios.get(
      `webKorcab/exportKunjungan?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}&idMarketing=${data.idMarketing}`,
      data
    );
    // console.log(response.data, "RESPONSE");
    return response.data;
  } catch (err) {
    console.error("Error fetching PDF:", err);
    throw err;
  }
}

export async function getKunjunganAllMarketing(dispatch, data, history) {
  // console.log(data, "AAA");
  try {
    const response = await baseAxios.get(
      `webKorcab/getKunjunganAllMarketing?page=${data.page}&limit=${data.limit}&cari=${data.cari}&tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_GET_KUNJUNGAN_ALL_SM", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function editFotoKunjungan(data) {
  try {
    const response = await baseAxios.post("webKorcab/editFotoKunjungan", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function ajukanRevisiKunjungan(data) {
  try {
    const response = await baseAxios.post("webKorcab/ajukanRevisi", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function showUrlDokumenKunjungan(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      "webKorcab/showUrlDokumenKunjungan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({
      type: "SHOW_URL_DOKUMEN_KUNJUNGAN",
      data: response.data.url,
    });
    return response;
  } catch (err) {
    return err.response;
  }
}
