import { baseAxios } from "../index";

export async function tambahKorcab(data) {
  console.log(data, "DATA");
  try {
    const response = await baseAxios.post("webKorcab/tambahKorcab", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    console.log(response, "ENDPOINT");
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function getAllKorcab(dispatch, data, history) {
  try {
    const response = await baseAxios("webKorcab/getAllKorcab", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALL_KORCAB", data: response.data });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function penempatanKorcab(data) {
  try {
    const response = await baseAxios.post("webKorcab/penempatanKorcab", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllCalonNasabah(dispatch, data, history) {
  console.log(data, "AAA");
  try {
    const response = await baseAxios.get(
      `webKorcab/getAllCalonNasabah?page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_CALON_NASABAH", data: response.data });
    console.log(response, "getAllCalonNasabah");
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getGroupKunjunganByCalonNasabah(dispatch, data, history) {
  // console.log(data, "AAA");
  try {
    const response = await baseAxios.get(
      `webKorcab/getGroupKunjunganByCalonNasabah?idCalonNasabah=${data.idCalonNasabah}&page=${data.page}&limit=${data.limit}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_GROUP_KUNJUNGAN_BYID", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getListKunjunganByGroup(dispatch, data, history) {
  // console.log(data, "AAA");
  try {
    const response = await baseAxios.get(
      `webKorcab/getListKunjunganByGroup?idGroup=${data.idGroupKunjungan}&page=${data.page}&limit=${data.limit}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_LIST_KUNJUNGAN_BYGROUP", data: response.data });
    // console.log(response, "getListKunjunganByGroup");
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function updateApprovalKunjungan(data) {
  // console.log(data, "AAA");
  try {
    const response = await baseAxios.post(
      "webKorcab/updateApprovalKunjungan",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    // console.log(response, "ENDPOINT");
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function settingMenuKorcab(data) {
  try {
    const response = await baseAxios.post("webKorcab/settingMenuKorcab", data, {
      headers: { token: localStorage.getItem("token") },
    });
    // dispatch({ type: "SET_ALL_PROFILE", data: response.data });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getExportKunjungan(data) {
  Swal.fire({
    title: "Konfirmasi Pengajuan",
    text: `Apakah Anda ingin mengexport dari ${moment(data.tglAwal).format(
      "DD MMMM YYYY"
    )} sampai ${moment(data.tglAkhir).format("DD MMMM YYYY")} ?`,
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Ya, Export",
    cancelButtonText: "Batal",
    allowOutsideClick: () => !Swal.isLoading(),
    showLoaderOnConfirm: true,
    preConfirm: () => {
      return new Promise((resolve) => {
        baseAxios
          .get(
            `webKorcab/exportKunjunganExcel?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
            {
              headers: { token: localStorage.getItem("token") },
              responseType: "blob",
            }
          )
          .then((respon) => {
            const url = window.URL.createObjectURL(new Blob([respon.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Export Kunjungan.xlsx`);
            document.body.appendChild(link);
            link.click();
            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Export Berhasil",
              text: "Data berhasil diexport.",
            });
            resolve();
          })
          .catch((err) => {
            console.log(err, "ERR");
            Swal.showValidationMessage(`Gagal melakukan ekspor: ${err}`);
            resolve();
          });
      });
    },
  });
}
